<template>
    <div @touchmove="bemove" style="font-size: .38rem;height:100vh;overflow: auto">
        <div ref="top" class="top-fixeds">
            <div>点击此联系墨帝服务处</div>
            <a href="tel:021-59830737">
                <van-icon name="phone-o"/>
            </a>
        </div>
        <div style="height:.95rem">

        </div>
        <div class="editor_wrap">
            <editor :content="article.content||'waiting'"></editor>
        </div>
    </div>

</template>

<script>
    import wx from 'weixin-js-sdk'
    import config from "./config";

    import editor from './Editor'

    export default {
        name: "Detail",
        data() {
            return {
                even: '',

                test: ``,

                article: {},
                share_id: '',

                start_y:0
            }
        },
        props: {
            /*编辑器的内容*/
            value: null
        },
        components: {
            editor
        },
        created() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            this.even = isAndroid ? 'an' : 'ios';
            this.url();
            this.getArticle();
        },
        mounted() {
            // this.$refs.myQuillEditor.quill.enable(false);
        },
        methods: {
            bemove(e){
                let move_y=e.changedTouches[0].clientY;
                console.log(this.start_y>move_y)
                if(this.start_y>move_y){
                    this.$refs['top'].style.opacity=0;
                }else if(move_y>this.start_y){
                    this.$refs['top'].style.opacity=1;
                }
                this.start_y=move_y;
            },
            onEditorFocus(event) {
                event.enable(false);
            },
            //如果是分享的文章
            //如果非分享，自己进来的文章
            getArticle() {
                this.$axios.get('miniprogram/articleDetails?article_id=' + this.$route.query.article_id).then((res) => {
                    if (res.data.code === '0000') {
                        this.article = res.data.content.article;
                        this.$route.query.share_id ? this.readAdd() : '';
                    }
                })

            },
            //分享。获得share_id
            url() {
                this.$axios.post('miniprogram/shareArticle', {
                    article_id: this.$route.query.article_id,
                }).then((res) => {
                    if (res.data.code === '0000') {
                        this.share_id = res.data.content.share_id;
                        this.share()
                    }
                })
            },
            //增加分享数量
            countAdd(ins) {
                // miniprogram/incArticleShareCount
                this.$axios.post('miniprogram/incArticleShareCount', {
                    article_id: this.$route.query.article_id,
                })
            },
            //增加分享积分
            shareAdd() {
                // /api/miniprogram/shareIntegral?share_id=49
                //     单独增加分享积分
                this.$axios.post('miniprogram/shareIntegral', {
                    share_id: this.share_id
                })
            },
            //增加阅读数量和阅读积分
            readAdd() {
                // miniprogram/incrementShareReadCount
                this.$axios.post('miniprogram/incrementShareReadCount', {
                    share_id: this.$route.query.share_id,
                })
            },
            //分享
            share() {
                let url = window.location.href;
                // url=url.split('?')[0]+'detail'
                // console.log(url.split('?')[0]+'detail')
                let that = this;
                config({
                    url, jsApiList: ['updateAppMessageShareData',
                        'onMenuShareAppMessage',
                        'onMenuShareTimeline',
                        'updateTimelineShareData',
                        'onMenuShareQQ']
                }, () => {
                    let str = window.location.href;
                    let str1 = str.indexOf('?');
                    let new_str = str.substring(str1, str.length);
                    let new_url = str.replace(new_str, '');

                    if (new_url.trim() === '') {
                        location.reload();
                    }
                    // let that = this;
                    // window.location.href=new_url
                    if (this.even === 'an') {
                        // 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
                        wx.onMenuShareTimeline({
                            title: that.article.share_title, // 分享标题
                            link: new_url + '?article_id=' + this.$route.query.article_id + '&share_id=' + this.share_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: that.article.share_cover, // 分享图标
                            success: function () {
                                // 用户点击了分享后执行的回调函数
                                that.countAdd(1);
                                that.shareAdd();
                            }
                        })
                        // 获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
                        wx.onMenuShareAppMessage({
                            title: that.article.share_title, // 分享标题
                            desc: that.article.share_desc, // 分享描述
                            link: new_url + '?article_id=' + this.$route.query.article_id + '&share_id=' + this.share_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: that.article.share_cover, // 分享图标
                            type: '', // 分享类型,music、video或link，不填默认为link
                            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                            success: function () {
                                // 用户点击了分享后执行的回调函数
                                that.countAdd(2);

                            },
                            trigger: function () {
                                that.shareAdd();
                            }
                        });
                        // 获取“分享到QQ”按钮点击状态及自定义分享内容接口（即将废弃）
                        wx.onMenuShareQQ({
                            title: that.article.share_title, // 分享标题
                            desc: that.article.share_desc, // 分享描述
                            link: new_url + '?article_id=' + this.$route.query.article_id + '&share_id=' + this.share_id, // 分享链接
                            imgUrl: that.article.share_cover, // 分享图标
                            success: function () {
                                // 用户点击了分享后执行的回调函数
                                that.countAdd(3);
                            },
                            trigger: function () {
                                that.shareAdd();
                            }
                        });
                    } else {
                        //分享到qq
                        wx.updateAppMessageShareData({
                            title: that.article.share_title, // 分享标题
                            desc: that.article.share_desc, // 分享描述
                            link: new_url + '?article_id=' + this.$route.query.article_id + '&share_id=' + this.share_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: that.article.share_cover, // 分享图标
                            success: function () {
                                // 用户点击了分享后执行的回调函数
                                that.countAdd(4);
                            },
                            trigger: function () {
                                that.shareAdd();
                            }
                        });
                        //分享到微信
                        wx.updateTimelineShareData({
                            title: that.article.share_title, // 分享标题
                            link: new_url + '?article_id=' + this.$route.query.article_id + '&share_id=' + this.share_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: that.article.share_cover, // 分享图标
                            success: function () {
                                // 用户点击了分享后执行的回调函数
                                that.countAdd(5);
                            },
                            trigger: function () {
                                that.shareAdd();
                            }
                        })
                    }
                })

            },
        }
    }
</script>

<style>
    /*@import "";*/
    a:link {
        color: #FF0000
    }

    /* 未访问的链接 */
    a:visited {
        color: #00FF00
    }

    /* 已访问的链接 */
    a:hover {
        color: #FF00FF
    }

    /* 当有鼠标悬停在链接上 */
    a:active {
        color: #0000FF
    }

    /* 被选择的链接 */
    .top-fixeds {
        font-size: .27rem;
        height: .35rem;
        padding: .3rem .2rem;
        display: flex;
        position: fixed;
        z-index:9999;
        width:calc(100% - .4rem);
        justify-content: space-between;
        background: rgb(238, 229, 162);
    }
</style>
